.entrenadores-section {
    height: 100vh; /* Ocupa toda la altura de la pantalla */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #E4E2DD;
  }
  
  .entrenadores-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
    /*writing-mode: vertical-rl;*/
  }
  
  .entrenadores-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 80%; /* Ocupa el 80% de la altura de la sección */
  }
  
  .entrenadores-carousel {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 60%;
    height: 100%;
    align-items: center; /* Centra las imágenes verticalmente */
  }
  
  .entrenador-img {
    flex: 0 0 auto;
    width: 25%;
    max-width: 250px; /* Limita el tamaño máximo de las imágenes */
    transition: transform 0.3s ease;
    cursor: pointer;
    margin-right: 10px;
    height: auto;
  }
  
  .entrenador-img.selected {
    transform: scale(1.8);
  }
  
  .entrenador-info {
    width: 35%;
    height: 100%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .entrenador-info h3 {
    margin: 0 0 10px;
    font-size: 1.5em;
    color: #333;
  }
  
  .entrenador-info p {
    margin: 10px 0;
    font-size: 1em;
    color: #666;
  }
  
  .entrenador-social {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .entrenador-social a {
    font-size: 1.5em;
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .entrenador-social a:hover {
    color: #ff5722;
  }
  
  @media (max-width: 768px) {
    .entrenadores-content {
      flex-direction: column;
      align-items: center;
      height: auto; /* Permite que el contenido se ajuste dinámicamente */
    }
  
    .entrenadores-carousel {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
  
    .entrenador-img {
      width: 50%;
      max-width: 100px; /* Ajusta el tamaño máximo de las imágenes para pantallas pequeñas */
    }
  
    .entrenador-info {
      width: 100%;
      height: auto;
    }
  }
  