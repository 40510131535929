.header-video-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Altura completa de la pantalla */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Asegúrate de que el video no se desborde */
}

.header-video {
  width: 100%;
  height: 100vh; /* Altura completa de la pantalla */
  object-fit: cover; /* Ajuste del video para que cubra todo el contenedor */
}
  
  .join-button {
    position: absolute;
    top: calc(50% + 38%); /* 50% de la altura del video + 40% para moverlo hacia abajo */
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px 80px; /* Aumenta el padding para hacer el botón más alargado */
    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro con opacidad del 50% */
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    border-radius: 25px; /* Bordes redondeados */
    transition: background-color 0.3s ease; /* Transición suave para el cambio de color */
  }
  
  .join-button:hover {
    background-color: rgba(0, 0, 0, 0.8); /* Reduce la opacidad al pasar el mouse */
  }

  .header-text {
    position: absolute;
    top: calc(50% + 30%); /* 50% de la altura del video + 40% para alinearlo con el botón */
    left: 80px; /* Posiciona el texto a 20px del borde izquierdo */
    transform: translateY(-50%);
    color: white;
    font-size: 2em; /* Ajusta el tamaño de fuente según prefieras */
    line-height: 1.2; /* Ajusta la altura de línea para el espaciado entre las líneas de texto */
    text-align: left;
  }

  /* Estilos responsivos */
@media (max-width: 768px) {
  .join-button {
    font-size: 1em;
    padding: 10px 20px;
  }

  .header-text {
    font-size: 1.5em;
    left: 10px;
  }
}

@media (max-width: 480px) {
  .join-button {
    font-size: 0.8em;
    padding: 8px 16px;
  }

  .header-text {
    font-size: 1.2em;
    left: 5px;
  }
}