.registro-container {
    padding: 20px;
    background-color: #f0f0f0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .registro-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .registro-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
  }
  
  .registro-form {
    flex: 1;
    margin-right: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .registro-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .registro-info {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  @media (max-width: 768px) {
    .registro-content {
      flex-direction: column;
      align-items: center;
    }
  
    .registro-form {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .registro-info {
      width: 100%;
    }
  }
  