.programas-section {
    height: 100vh; /* Altura completa de la pantalla */
    padding: 40px 20px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centra el contenido verticalmente */
  }

  .tittle-content {
    display: flex;
    flex: 1;
    justify-content: space-between; /* Espacia los elementos */
    align-items: center; /* Alinea los elementos al inicio */
  }

  .tittle,
  .tittle-links {
    flex: 1;  
    max-width: 50%;
  }
  
  .programas-tittle {
    font-size: 4em;
    margin-bottom: 10px;
    color: #333; /* Color del texto del título */
    margin-top: 60px; /* Mueve el título más abajo */

  }
  
  .programas-content {
    display: flex;
    flex: 1;
    justify-content: space-between; /* Espacia los elementos */
    align-items: center; /* Alinea los elementos al inicio */
  }

  .programas-video,
  .programas-text {
    flex: 1;
    max-width: 50%;
  }

  .programas-video {
    margin-right: 50px;
  }  

  .programas-video video {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .programas-text {
    font-size: 1.2em;
    color: #555; /* Color del texto */
    display: flex;
    flex-direction: column;
  }

  .programas-description {
    flex: 1; /* Ocupa el 90% restante del espacio */
  }
  
  .programas-description p {
    margin: 0; /* Elimina el margen predeterminado del párrafo */
  }

  .programas-links {
    display: flex;
    justify-content: space-evenly; /* Espacia los elementos */
    margin-bottom: 0px; /* Espacio entre los links y el párrafo */
    flex: 1; /* Ocupa todo el espacio disponible verticalmente */
    align-items: flex-start;
    transition: all 0.3s ease; /* Transición suave para el cambio de estilos */
    font-weight: bold;
    color: #333;
  }

  .programas-link.selected {
    color: #ff5722; /* Color de texto para el enlace seleccionado */
  }

  .programas-links a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-size: 1.2em;
    width: 10%; /* Ancho del 10% para los links */
  }
  
  .programas-links a:hover {
    color: #007bff; /* Cambia el color al pasar el mouse */
  }

  /* Estilos responsivos */
@media (max-width: 768px) {
  .programas-content {
    flex-direction: column;
  }

  .programas-video {
    margin-right: 0;
    margin-bottom: 20px;
    max-width: 100%;
  }

  .programas-text {
    font-size: 1em;
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .tittle-content{
    flex-direction: column;
  }
  .programas-title {
    font-size: 1.1em;
  }

  .programas-text {
    font-size: 0.9em;
  }

  .programas-links{
    font-size: 0.7em;
  }
  }
