.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(51, 51, 51, 0.7); /* Fondo oscuro con opacidad del 70% */
    padding: 10px 20px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    transition: background-color 0.3s ease, padding 0.3s ease;
    box-sizing: border-box; /* Asegura que el padding esté incluido en el ancho total */
    font-size: 1.2em;
  }

  .menu.scrolled {
    background-color: rgba(51, 51, 51, 1); /* Fondo completamente opaco al hacer scroll */
    padding: 5px 20px; /* Ajusta el padding según prefieras */
  }
  /*.menu:hover {
    background-color: rgba(51, 51, 51, 1); /* Fondo completamente opaco al pasar el mouse 
  }*/

  .menu-section {
    display: flex;
    align-items: center;
  }
  
  .menu-left {
    justify-content: flex-start;
  }
  
  .menu-center {
    justify-content: center;
    flex-grow: 1; /* Esto hace que el centro ocupe el espacio restante */
  }
  
  .menu-right {
    justify-content: flex-end;
  }
  


  .menu-section a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s ease; /* Transición suave para el color del texto */
    white-space: nowrap; /* Evita que el texto se rompa en varias líneas */
  }
  
  .menu-section a:hover {
    color: #ddd;
  }
  
  .logo {
    max-height: 40px; /* Ajusta esta altura según tus necesidades */
    height: auto; /* Mantiene la proporción del logo */
  }
  
  .icon {
    width: 24px; /* Ajusta este valor para cambiar el ancho del icono */
    height: 24px; /* Ajusta este valor para cambiar la altura del icono */
    margin-right: 5px;
  }

  /* Estilos para el menú hamburguesa en dispositivos móviles */
  .hamburger {
    display: none; /* Oculta el icono de hamburguesa por defecto */
    flex-direction: column;
    cursor: pointer;
  }

  .hamburger div {
    width: 25px;
    height: 2px;
    background-color: white;
    margin: 3px 0;
  }
  
  /* Estilos responsivos */
  @media (max-width: 768px) {
    .menu {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .menu-section {
      display: none; /* Oculta las secciones del menú por defecto en dispositivos móviles */
      flex-direction: column;
      width: 90%;
      padding: 10px 20px;
      background-color: rgba(51, 51, 51, 0.9); /* Fondo oscuro con mayor opacidad */
    }
  
    .menu-section.open {
      display: flex; /* Muestra las secciones del menú cuando el menú está abierto */
    }
  
    .menu-left, .menu-right, .menu-center {
      justify-content: flex-start;
    }
  
    .hamburger {
      display: flex; /* Muestra el icono de hamburguesa en dispositivos móviles */
    }
  
    .logo {
      margin-bottom: 10px; /* Añade margen debajo del logo en móviles */
    }
  
    .menu-section a {
      margin: 10px 0; /* Ajusta el margen para elementos verticales */
    }
   } 